import Cookies from "js-cookie";

const LANGUAGE_PREFERENCE_STORAGE_KEY = "django_language";

export class LanguagePreference {
  static storeLanguage(language) {
    return Cookies.set(LANGUAGE_PREFERENCE_STORAGE_KEY, language, {
      sameSite: "strict",
      secure: true,
      expires: 30,
    });
  }

  static getStoredLanguage() {
    return Cookies.get(LANGUAGE_PREFERENCE_STORAGE_KEY);
  }
}
