

/**
 * 
 ***** RELATED IMPORTS *****
 * 
 * assets/sass/components/_accordion.scss
 * 
 ***** USAGE *****
 * 
 * HTML
 * 
 * 
 * <div class="accordion">
 *      <div class="accordion__toggle">
 *          Title
 *      </div>
 *      <div class="accordion__content-wrapper">
 *          <div class="accordion__content-wrapper__content">
 *              <!-- content -->
 *          </div>
 *      </div>
 *  </div>
 * 
 * 
 * JAVASCRIPT
 * 
 * new Accordion(element, OPTIONS);
 * 
 * -- element: the html element to convert to this component
 * -- OPTIONS: component options, check DEFAULT_OPTIONS below
 * 
 ***** ACCESSIBILITY TO THE CLASS ****
 * 
 * const storedAccordion = new Accordion();
 * document.querySelector("#accordion-id").Accordion;
 * 
 ***** PUBLIC METHODS *****
 *
 * isActive() - returns true/false depending on the accordion state
 * toggleActive(boolean) - changes the accordion state
 * 
 * 
 */

const DEFAULT_OPTIONS = {
    enableIcon: true
}

export class Accordion {
    constructor(element, options) {

        this.options = { ...DEFAULT_OPTIONS, ...options };

        if (element.Accordion) return;

        this.element = element;
        this.toggleZone = element.querySelector('.accordion__toggle');
        this.wrapper = element.querySelector('.accordion__content-wrapper');
        this.content = element.querySelector('.accordion__content-wrapper__content');

        this.element.Accordion = this;

        this.element.classList.add("accordion");

        this.refreshHeight();

        if (this.options.enableIcon) {
            this.toggleZone.innerHTML += `
                <svg class="accordion__toggle__icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.25105 6.25105C9.58579 5.91632 10.1285 5.91632 10.4632 6.25105L15.6061 11.3939C15.9408 11.7286 15.9408 12.2714 15.6061 12.6061L10.4632 17.7489C10.1285 18.0837 9.58579 18.0837 9.25105 17.7489C8.91632 17.4142 8.91632 16.8715 9.25105 16.5368L13.7878 12L9.25105 7.46323C8.91632 7.1285 8.91632 6.58579 9.25105 6.25105Z" fill="currentColor"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="currentColor"/>
                </svg>
            `;
        }

        this.toggleZone.addEventListener("click", () => {
            this.toggleActive();
        });

    }

    isActive = () => this.element.classList.contains("accordion--active");

    toggleActive = () => {
        if (this.isActive()) this.element.classList.remove("accordion--active");
        else this.element.classList.add("accordion--active");
        this.refreshHeight();
    }

    refreshHeight = () => {
        const isActive = this.isActive();
        const contentHeight = this.content.offsetHeight;

        if (isActive) {
            this.wrapper.style.height = `${contentHeight}px`;
            setTimeout(() => {
                this.wrapper.style.height = 'auto';
            }, 300);
        }
        else {
            this.wrapper.style.height = `${contentHeight}px`;
            setTimeout(() => this.wrapper.style.height = 0, 0);
        }

    }


}