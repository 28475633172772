import { Accordion } from "./accordion";
import { LanguagePreference } from "../language-preference";

$(function () {
  const navbarView = new NavbarView();
  navbarView.bindOnLanguageSelection((language) => {
    LanguagePreference.storeLanguage(language.toLowerCase());
  });
});

export class NavbarView {
  constructor() {
    this.navbarClass = "navbar";
    this.navbarActiveClass = `${this.navbarClass}--active`;
    this.scrolledClass = `${this.navbarClass}--scrolled`;
    this.isScrolledDown = false;

    this.menuClass = "menu";
    this.activeMenuClass = `${this.menuClass}--active`;
    this.isMenuOpen = false;

    this.navigationClass = `${this.navbarClass}__navigation`;
    this.navigationItemDropdownActiveClass = `${this.navigationClass}__item--dropdown-active`;
    this.dropdownWrapperClass = `${this.navigationClass}__dropdown-wrapper`;
    this.dropdownWrapperHiddenClass = `${this.dropdownWrapperClass}--hidden`;
    this.dropdownWrapperActiveClass = `${this.dropdownWrapperClass}--active`;
    this.dropdownClass = `${this.dropdownWrapperClass}__dropdown`;
    this.dropdownItemClass = `${this.dropdownClass}__item`;
    this.dropdownItemActiveClass = `${this.dropdownItemClass}--active`;

    this.searchWrapperClass = `search-wrapper`;
    this.searchWrapperActiveClass = `${this.searchWrapperClass}--active`;
    this.searchWrapperCloseClass = `${this.searchWrapperClass}__close`;
    this.searchWrapperContentClass = `${this.searchWrapperClass}__content`;
    this.searchInputClass = `${this.searchWrapperContentClass}__input-wrapper__input`;
    this.searchInputIconClass = `${this.searchWrapperContentClass}__input-wrapper__icon`;

    this.navigation = document.querySelector(`.${this.navigationClass}`);
    this.dropdownWrapper = this.navigation.querySelector(
      `.${this.dropdownWrapperClass}`
    );
    this.dropdown = this.dropdownWrapper.querySelector(
      `.${this.dropdownClass}`
    );
    this.navigationItems = this.navigation.querySelectorAll(
      `.${this.navigationClass}__item`
    );

    this.menu = document.querySelector(`.${this.menuClass}`);
    this.navbar = document.querySelector(`.${this.navbarClass}`);
    this.openMenuButton = document.getElementById("navbar-menu");
    this.closeMenuButton = document.getElementById("navbar-menu-close");

    this.searchWrapper = document.querySelector(`.${this.searchWrapperClass}`);
    this.searchWrapperContent = this.searchWrapper.querySelector(
      `.${this.searchWrapperContentClass}`
    );
    this.searchInput = this.searchWrapper.querySelector(
      `.${this.searchInputClass}`
    );
    this.searchInputIcon = this.searchWrapper.querySelector(
      `.${this.searchInputIconClass}`
    );
    this.searchClose = this.searchWrapper.querySelector(
      `.${this.searchWrapperCloseClass}`
    );
    this.searchButton = this.navbar.querySelector(".navbar__shortcut__icon");
    this.documentHtml = document.querySelector("html");
    this.isSearchActive = false;

    this.languageSelectorLists = Array.from(
      document.querySelectorAll(".language-selector__dropdown")
    );

    this.init();
  }

  searchKeyPress = (ev) => {
    switch (ev.key) {
      case "Escape":
        this.toggleSearch(false);
        break;
      case "Enter":
        this.doSearch();
        break;
      default:
        break;
    }
  };

  doSearch = () => {
    const text = this.searchInput.value;
    if (text) window.location.href = general_search_link + "?q=" + text;
  };

  toggleSearch = (enabled) => {
    if (this.isSearchActive == enabled) return;

    this.isSearchActive = enabled;

    if (enabled) {
      this.searchWrapper.style.display = "flex";
      setTimeout(() => {
        this.searchWrapper.classList.add(this.searchWrapperActiveClass);
        this.searchInput.focus();
      }, 0);
      this.documentHtml.classList.add("search-enabled");
      document.addEventListener("keyup", this.searchKeyPress);
    } else {
      this.searchWrapper.classList.remove(this.searchWrapperActiveClass);
      setTimeout(() => {
        if (!this.isSearchActive) this.searchWrapper.style.display = "none";
      }, 300);
      this.documentHtml.classList.remove("search-enabled");
      document.removeEventListener("keyup", this.searchKeyPress);
    }
  };

  init() {
    // search

    this.searchButton.addEventListener("click", () => this.toggleSearch(true));
    this.searchClose.addEventListener("click", () => this.toggleSearch(false));
    this.searchInputIcon.addEventListener("click", this.doSearch);
    this.searchWrapper.addEventListener("click", () =>
      this.toggleSearch(false)
    );
    this.searchWrapperContent.addEventListener("click", (e) =>
      e.stopPropagation()
    );

    // end search

    window.addEventListener("scroll", () => this.onNavbarScroll());

    document
      .querySelectorAll(".accordion")
      .forEach((element) => new Accordion(element));

    const setHiddenClass = () => {
      setTimeout(() => {
        if (
          !this.dropdownWrapper.classList.contains(
            this.dropdownWrapperActiveClass
          )
        ) {
          this.dropdownWrapper.classList.add(this.dropdownWrapperHiddenClass);
        }
      }, 250);
    };

    this.navigation.addEventListener("mouseleave", () => {
      this.dropdown.classList.remove(this.dropdownActiveClass);
      this.dropdownHoveredElement = null;
      this.dropdownWrapper.style.height = `0px`;
      this.dropdownWrapper.classList.remove(this.dropdownWrapperActiveClass);
      const item = this.navigation.querySelector(
        `.${this.navigationItemDropdownActiveClass}`
      );
      if (item) item.classList.remove(this.navigationItemDropdownActiveClass);
      setHiddenClass();
    });

    const updateDropdown = (el, items) => {
      const parentLeft = this.navigation.offsetLeft;
      const targetLeft = el.offsetLeft - parentLeft;

      if (!items.length) {
        this.dropdownHoveredElement = null;
        this.dropdown.innerHTML = "";
        this.dropdownWrapper.style.height = `0px`;
        this.dropdownWrapper.classList.remove(this.dropdownWrapperActiveClass);
        setHiddenClass();
        return;
      }

      el.classList.add(this.navigationItemDropdownActiveClass);

      let html = "";

      items.forEach((item) => {
        const { url, isActive, name } = item;

        html += `<a href="${url}" class="${this.dropdownItemClass}${
          isActive ? ` ${this.dropdownItemActiveClass}` : ""
        }">${name}</a>`;
      });

      this.dropdown.innerHTML = html;
      this.dropdownWrapper.style.height = `${this.dropdown.offsetHeight}px`;
      this.dropdownWrapper.style.transform = `translateX(${targetLeft}px)`;
      this.dropdownWrapper.classList.add(this.dropdownWrapperActiveClass);
    };

    const getElementData = (el) => {
      if (!el.parsedData) {
        el.parsedData = JSON.parse(el.getAttribute("data") || "[]");
      }

      return el.parsedData;
    };

    this.navigationItems.forEach((el) => {
      el.addEventListener("mouseover", () => {
        if (this.dropdownHoveredElement == el) return;
        this.dropdownHoveredElement = el;

        const item = this.navigation.querySelector(
          `.${this.navigationItemDropdownActiveClass}`
        );
        if (item) item.classList.remove(this.navigationItemDropdownActiveClass);

        const items = getElementData(el);

        if (
          this.dropdownWrapper.classList.contains(
            this.dropdownWrapperHiddenClass
          )
        ) {
          if (!items.length) return;

          const parentLeft = this.navigation.offsetLeft;
          const targetLeft = el.offsetLeft - parentLeft;
          this.dropdownWrapper.style.transform = `translateX(${targetLeft}px)`;
          setTimeout(() => {
            this.dropdownWrapper.classList.remove(
              this.dropdownWrapperHiddenClass
            );
            updateDropdown(el, items);
          }, 10);
        } else updateDropdown(el, items);
      });
    });

    this.openMenuButton.addEventListener("click", () => this.updateMenu(true));
    this.closeMenuButton.addEventListener("click", () =>
      this.updateMenu(false)
    );

    this.watchLanguageSelection();
  }

  onNavbarScroll() {
    const THRESHOLD = 4;
    const scrolledY = Math.round(window.scrollY);
    const remValue = parseInt(window.getComputedStyle(document.body).fontSize);
    const scrollInRem = Math.min(THRESHOLD, (scrolledY * 1) / remValue);

    const userSrolledDown = scrollInRem === THRESHOLD;

    if (userSrolledDown && !this.isScrolledDown) {
      this.navbar.classList.add(this.scrolledClass);
      this.isScrolledDown = true;
    } else if (!userSrolledDown && this.isScrolledDown) {
      this.navbar.classList.remove(this.scrolledClass);
      this.isScrolledDown = false;
    }
  }

  updateMenu(isOpen) {
    this.isMenuOpen = isOpen;

    if (isOpen) {
      document.documentElement.style.overflow = "hidden";
      this.navbar.classList.add(this.navbarActiveClass);
      this.menu.classList.add(this.activeMenuClass);
    } else {
      document.documentElement.style.overflow = "";
      this.navbar.classList.remove(this.navbarActiveClass);
      this.menu.classList.remove(this.activeMenuClass);
    }
  }

  watchLanguageSelection() {
    this.languageSelectorLists.forEach((languageList) => {
      languageList.addEventListener(
        "click",
        (e) => {
          const languageItem = e.target.closest(
            `.language-selector__dropdown__item`
          );

          if (languageItem) {
            const language = languageItem.getAttribute("data-language");

            this.onLanguageSelection(language);
          }
        },
        { passive: true }
      );
    });
  }

  bindOnLanguageSelection = (callback) => {
    this.onLanguageSelection = callback;
  };
}
